import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { changeCandidateUpdateStatus } from '../actions/candidatesOperationActions';
import { candidatesActions } from '../actions/index';
import { CandidateUpdatingStatus } from '../enums/candidateEnums';
import * as OpTypes from '../types/candidatesOperationTypes';

function* updateCandidate(action: any): any {
  yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Updating));

  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(candidatesActions.updatedCandidate(response.data));
    yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Success));
  } else {
    yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Failure));
  }
}

export function* updateCandidateSaga() {
  yield takeLatest(OpTypes.UPDATE_CANDIDATE, updateCandidate);
}

function* updateCandidatePhoto(action: any): any {
  yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Updating));
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(candidatesActions.updatedCandidate(response.data));
    yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Success));
  } else {
    yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Failure));
  }
}

export function* updateCandidatePhotoSaga() {
  yield takeLatest(OpTypes.UPDATE_CANDIDATE_PHOTO, updateCandidatePhoto);
}

export function* deleteCandidatePhoto(action: any) {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* deleteCandidatePhotoSaga() {
  yield takeLatest(OpTypes.DELETE_CANDIDATE_PHOTO, deleteCandidatePhoto)
}

function* removeCandidateFromSession(action: any): any {
  yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Updating));
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(candidatesActions.updatedCandidate(response.data));
    yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Success));
  } else {
    yield put(changeCandidateUpdateStatus(CandidateUpdatingStatus.Failure));
  }
}

export function* removeCandidateFromSessionSaga() {
  yield takeLatest(OpTypes.REMOVE_CANDIDATE_FROM_SESSION, removeCandidateFromSession);
}
