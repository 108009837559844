import { Feature } from '../../../../common/enums/appEnums';

export const GET_CANDIDATES = `GET_CANDIDATES${Feature.Candidates}`;
export const CREATE_CANDIDATE = `CREATE_CANDIDATE${Feature.Candidates}`;
export const REMOVE_CANDIDATE_FROM_SESSION = `REMOVE_CANDIDATE_FROM_SESSION${Feature.Candidates}`;
export const GET_LOCATIONS = `GET_LOCATIONS${Feature.Candidates}`;
export const GET_LOCATIONS_SUCCEED = `GET_LOCATIONS_SUCCEED${Feature.Candidates}`;
export const GET_LOCATIONS_FAILED = `GET_LOCATIONS_FAILED${Feature.Candidates}`;
export const UPDATE_CANDIDATE = `UPDATE_CANDIDATE${Feature.Candidates}`;
export const DELETE_CANDIDATE_PHOTO = `DELETE_CANDIDATE_PHOTO${Feature.Candidates}`;
export const UPDATE_CANDIDATE_PHOTO = `UPDATE_CANDIDATE_PHOTO${Feature.Candidates}`;
export const GET_CANDIDATE = `GET_CANDIDATE${Feature.Candidates}`;
export const GET_ETHNICITIES = `GET_ETHNICITIES${Feature.Candidates}`;
export const GET_GENDERS = `GET_GENDERS${Feature.Candidates}`;
export const GET_CANDIDATE_STATUSES = `GET_CANDIDATE_STATUSES${Feature.Candidates}`;
export const GET_CANDIDATE_STATUSES_SUCCEED = `GET_CANDIDATE_STATUSES_SUCCEED${Feature.Candidates}`;
export const CHANGE_CANDIDATE_CREATION_STATUS = `CHANGE_CANDIDATE_CREATION_STATUS${Feature.Candidates}`;
export const CHANGE_CANDIDATE_UPDATING_STATUS = `CHANGE_CANDIDATE_UPDATING_STATUS${Feature.Candidates}`;
export const CHANGE_SELECTED_CANDIDATE = `CHANGE_SELECTED_CANDIDATE${Feature.Candidates}`;
export const GET_CANDIDATE_RECRUITERS = `GET_CANDIDATE_RECRUITERS${Feature.Candidates}`;
export const FILTER_CANDIDATES = `FILTER_CANDIDATES${Feature.Candidates}`;
export const BULK_IMPORT_CANDIDATES = `BULK_IMPORT_CANDIDATES${Feature.Candidates}`;
export const BULK_IMPORT_CANDIDATES_SUCCEED = `BULK_IMPORT_CANDIDATES_SUCCEED${Feature.Candidates}`;
export const BULK_IMPORT_CANDIDATES_FAILED = `BULK_IMPORT_CANDIDATES_FAILED${Feature.Candidates}`;
export const BULK_IMPORT_CANDIDATES_CLEAR_ERRORS = `BULK_IMPORT_CANDIDATES_CLEAR_ERRORS${Feature.Candidates}`;
export const SAVE_BULK_IMPORT_CANDIDATES = `SAVE_BULK_IMPORT_CANDIDATES${Feature.Candidates}`;
export const SAVE_BULK_IMPORT_CANDIDATES_SUCCEED = `SAVE_BULK_IMPORT_CANDIDATES_SUCCEED${Feature.Candidates}`;
export const SAVE_BULK_IMPORT_CANDIDATES_FAILED = `SAVE_BULK_IMPORT_CANDIDATES_FAILED${Feature.Candidates}`;
export const SAVE_BULK_IMPORT_CANDIDATES_CLEAR_STATUS = `SAVE_BULK_IMPORT_CANDIDATES_CLEAR_STATUS${Feature.Candidates}`;
export const CLEAR_BULK_IMPORT_CANDIDATES = `CLEAR_BULK_IMPORT_CANDIDATES${Feature.Candidates}`;
