import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getInstitutions, getLocations, getQualifications } from 'common/operations/actions/commonOperationActions';
import { getCandidateStatuses } from 'modules/manage-app/operations/actions/candidateStatusActions';
import { getOpenDaySessions } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { useNavigate, useParams } from 'react-router-dom';
import { IGlobalState } from '../../../startup/store/globalState';
import CandidateFormGrid from '../components/CandidateFormGrid/CandidateFormGrid';
import { getCandidate, getEthnicities, getGenders } from '../operations/actions/candidatesOperationActions';

const CandidateDetailsContainer: React.FC = () => {
  const navigate = useNavigate();
  const id = Number(useParams().id);
  const dispatch = useDispatch();

  const { locations, genders, ethnicities, institutions, qualifications, openDays, candidateStatuses } = useSelector(
    (state: IGlobalState) => {
      return {
        locations: state.commonState.common.locations,
        genders: state.candidatesState.candidates.genders,
        ethnicities: state.candidatesState.candidates.ethnicities,
        institutions: state.commonState.common.institutions,
        qualifications: state.commonState.common.qualifications,
        openDays: state.sessionsState.sessions.sessions,
        candidateStatuses: state.candidateStatusState.statuses
      };
    }
  );

  useEffect(() => {
    !locations?.length && dispatch(getLocations());
    !genders?.length && dispatch(getGenders());
    !ethnicities?.length && dispatch(getEthnicities());
    !institutions?.length && dispatch(getInstitutions());
    !qualifications?.length && dispatch(getQualifications());
    !openDays?.data?.length && dispatch(getOpenDaySessions(''));
    !candidateStatuses?.length && dispatch(getCandidateStatuses());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    id && dispatch(getCandidate(`${id}/edit`));
  }, [id]);

  const handleClose = () => {
    navigate(-1);
  };

  return <CandidateFormGrid candidateId={id} closeHandler={() => handleClose()} />;
};

export default CandidateDetailsContainer;
