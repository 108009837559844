import { bulkImportCandidatesSaga, saveBulkImportCandidatesSaga } from './candidateBulkImportSaga';
import { changeSelectedCandidateSaga } from './changeSelectedCandidateSaga';
import { createCandidateSaga } from './createCandidateSaga';
import { filterCandidatesSaga } from './filterCandidatesSaga';
import { getCandidateSaga } from './getCandidateSaga';
import { getCandidateRecruitersSaga, getCandidatesSaga } from './getCandidatesSaga';
import { getEthnicitiesSaga } from './getEthnicitiesSaga';
import { getGendersSaga } from './getGendersSaga';
import {
  deleteCandidatePhotoSaga,
  removeCandidateFromSessionSaga,
  updateCandidatePhotoSaga,
  updateCandidateSaga
} from './updateCandidateSaga';
export const candidatesSagas = [
  getCandidatesSaga(),
  getCandidateSaga(),
  createCandidateSaga(),
  updateCandidateSaga(),
  getGendersSaga(),
  getEthnicitiesSaga(),
  getCandidateRecruitersSaga(),
  changeSelectedCandidateSaga(),
  filterCandidatesSaga(),
  bulkImportCandidatesSaga(),
  saveBulkImportCandidatesSaga(),
  updateCandidatePhotoSaga(),
  deleteCandidatePhotoSaga(),
  removeCandidateFromSessionSaga()
];
