import { PhotoCameraOutlined } from '@mui/icons-material';
import { Box, Button, Fade, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { ChangeEvent, useState } from 'react';
import FormDropzone from '../FormDropzone/FormDropzone';
import ImageCropper from '../ImageCropper/ImageCropper';
import styles from './CandidateForm.module.scss';

interface IProps {
  //handleDelete
  handleDelete: () => void;
  handleUpload: (file: Blob | MediaSource | null) => void;
  handlePicSave: () => void;
  uploadedImg: string;
  hasEditCandidatePermission?: boolean;
  isDefaultImg: boolean;
}

export const ImageSection: React.FC<IProps> = ({
  handleDelete,
  handleUpload,
  handlePicSave,
  uploadedImg,
  hasEditCandidatePermission = false,
  isDefaultImg = true
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [candidateImageChanged, setCandidateImageChanged] = useState<boolean>(false);
  const handleCrop = async (cropped: any) => {};

  const handlePicChange = (event?: ChangeEvent<HTMLInputElement>, uploadFile?: Blob | MediaSource | null) => {
    setCandidateImageChanged(true);
    if (uploadFile) {
      handleUpload(uploadFile);
    } else if (event?.target.files) {
      handleUpload(event.target.files[0]);
    }
  };

  const handleCancelPicSave = () => {
    setCandidateImageChanged(false);
  };

  const open = Boolean(anchorEl);
  return (
    <Grid item xs={12} sm={12} md={3} lg={2}>
      <Box
        className={styles.uploadContainer}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {uploadedImg ? (
          <div className={styles.cropperContainer}>
            <ImageCropper
              imageSrc={uploadedImg}
              isDefaultImg={isDefaultImg}
              onImgCrop={handleCrop}
              onRemoveImg={handleUpload}
              handleDelete={handleDelete}
              hasEditPermission={hasEditCandidatePermission}
            />
          </div>
        ) : (
          hasEditCandidatePermission && (
            <div className={styles.dropzoneContainer}>
              <FormDropzone fileUploaded={(fileUrl) => handlePicChange(undefined, fileUrl)} />
            </div>
          )
        )}
        {hasEditCandidatePermission ? (
          <Button
            className={`${styles.desktopUploadButton} ${styles.w100}`}
            variant="outlined"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event: any) => setAnchorEl(event.currentTarget)}
          >
            <IconButton sx={{ color: styles.entelectBlue }} aria-label="upload picture" component="label">
              <PhotoCameraOutlined />
            </IconButton>
          </Button>
        ) : (
          <div className={`${styles.placeHolderForNoUploadButton}`}></div>
        )}
        {candidateImageChanged ? (
          <Grid container item xs={12}>
            <Grid item xs={6} className={styles.imageSaveActions}>
              <EntelectButton
                variant={ButtonType.Primary}
                handleClick={handlePicSave}
                label={'upload'}
              ></EntelectButton>
            </Grid>
            <Grid item xs={6} className={styles.imageCancelActions}>
              <EntelectButton
                variant={ButtonType.Secondary}
                handleClick={handleCancelPicSave}
                label={'Cancel'}
              ></EntelectButton>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem>
            <Button variant="text" component="label" className={styles.w100}>
              Choose from library
              <input hidden accept="image/*" type="file" onChange={(event) => handlePicChange(event, null)} />
            </Button>
          </MenuItem>
          <MenuItem onClick={() => setAnchorEl(null)}>
            <Button variant="text" component="label" className={styles.w100}>
              Cancel
            </Button>
          </MenuItem>
        </Menu>
      </Box>
    </Grid>
  );
};
